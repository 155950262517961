.image{
  position:relative;
  overflow:hidden;
  padding-bottom:100%;
}
.image img{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    object-fit: cover;
}